import { GetServerSideProps } from "next";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import getTranslations from "src/helpers/localization-helpers/getTranslations";
import Container from "src/sections/container/Container";
import ErrorComponent from "src/sections/error/Error";
import Layout from "src/sections/layout/Layout";

type Props = {};

/**
 * Does not show specific errors to the app user.
 */

function ErrorPage({}: Props) {
  const t = useTranslations("Errors");
  const router = useRouter();

  const handleRefresh = () => {
    router.reload();
  };

  useEffect(() => {
    const timeoutId = setTimeout(handleRefresh, 4000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Layout pageTitle={t("not_found_title")}>
      <Container>
        <ErrorComponent
          statusCode="404"
          title={t("not_found")}
          subtitle={t("go_back_to_your_shemsi")}
          href="/"
          buttonText={t("homepage_button")}
        />
      </Container>
    </Layout>
  );
}

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  if (!locale) {
    throw new Error(`locale is not passed to getServerSideProps`);
  }

  const translations = getTranslations(locale);

  return {
    props: {
      translations,
    },
  };
};

export default ErrorPage;
